<template>
	<div>
		<div class="table">
			<div class="info">
				<span v-if="Status == 'school'">
					<span class="back" @click="$router.go(-1)">班级入离校信息</span>
					/ 学生入离校信息
				</span>
				<span v-else>
					学生入离校信息
				</span>
			</div>
			<div class="seach" v-if="Status != 'school'">
				<span>时间：</span>
				<el-date-picker class="time" size="medium" type="daterange" v-model="picker" start-placeholder="开始日期"
					range-separator="-" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"
					unlink-panels @change="pickerChange">
				</el-date-picker>
			</div>
			<el-table :data="data" v-loading="loading">
				<el-table-column type="index" label="序号" align="center"></el-table-column>
				<el-table-column prop="stuName" label="姓名" align="center"></el-table-column>
				<el-table-column prop="stu_id" label="学号" align="center"></el-table-column>
				<el-table-column label="入校时间" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.arrive == '0' ? '无' : scope.row.arrive}}</span>
					</template>
				</el-table-column>
				<el-table-column label="离校时间" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.leave == '0' ? '无' : scope.row.leave}}</span>
					</template>
				</el-table-column>
				<el-table-column label="家长接送" align="center">
					<template slot-scope="scope">
						<span style="margin-right: 5px;">到校:{{scope.row.arrivehaveGua == '0' ? '无' : '有'}}</span>
						<span>离校:{{scope.row.leavehaveGua == '0' ? '无' : '有'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="有无异常" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.error == '0' ? '无' : '有'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="查看详情" align="center" width="100">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="detail(scope.row)">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div>
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total, prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px;text-align: center;">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		classArriveByDate
	} from '@/api/TeacherApi.js'
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				data: [], //学生出入数据
				loading: false, //加载框
				classId: this.$route.query.classId, //班级id
				date: this.$route.query.date, //查看班级的日期
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				picker: null, // 查询的日期
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近半个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}]
				}
			}
		},
		created() {
			this.getData(1) // 学生出入列表
		},
		computed: {
			...mapGetters(["Status"]),
		},
		methods: {
			async getData(currpage, picker) { // 学校班级学生出入列表
				this.loading = true;
				const data = {
					page: currpage, //	当前页数
					limit: this.pagesize, // 每页条数
					classId: this.classId || JSON.parse(localStorage.getItem('userInfo')).ClassID, //班级id
					date: this.date || '', // 日期
					startDate: !!picker ? picker[0] : '', //开始日期
					endDate: !!picker ? picker[1] : '' //结束日期
				}
				let res = await classArriveByDate(data); //请求接口
				if(res.status.code == 1){
					this.CountNum = parseInt(res.data.CountNum); // 总条数
					this.data = res.data.List; // 班级学生出入列表数据
					this.currpage = currpage; //分页显示当前页
				}else{
					this.$message.error(res.status.msg);
				}
				this.loading = false;
			},
			detail(row) { //跳去详情页
				let userInfo = {
					stuId: row.stuId,
					stuDate: row.date,
					stuName: row.stuName
				};
				this.$router.push({
					path: '/detail',
					query: {
						userInfo: JSON.stringify(userInfo)
					}
				})
			},
			pickerChange(e) { //选择日期
				this.getData(1, e); // 根据选取的日期获取班级出入列表数据
			},
			handleCurrentChange(val) { // 分页
				this.getData(val);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		padding: 20px 50px;

		.info {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: 20px;
			font-weight: bold;
			line-height: 50px;

			.back {
				color: #79740E;
				cursor: pointer;
			}
		}

		.seach {
			display: flex;
			align-items: center;

			.time {
				margin-left: 20px;
				width: 300px;
			}
		}

	}
</style>
